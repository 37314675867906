import React from 'react';
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag";
import BrandLogo from "../images/icons/logo.svg";
import PropertyBreadcrumb from "../components/Breadcrumb/PropertyBreadcrumb"
import PropertyDetailsSlider from "../components/PropertyDetailsSlider/PropertyDetailsSlider"
import PropertyDetailsDescription from "../components/PropertyDetailsDescription/PropertyDetailsDescription"
import PropertyDetailsMap from "../components/PropertyDetailsMap/PropertyDetailsMap"
import PropertyAreaGuide from "../components/ProeprtyAreaGuide/PropertyAreaGuide"
import SimilarProperties from "../components/SimilarProperties/SimilarProperties"
import FullWidthBlueBanner from "../components/SearchBanner/FullwidthBlueBanner"
import SEO from "../components/Seo/seo"
import { UserObjectStoreProvider } from "@starberry/myaccount-website-utils";
import NotFoundPage from "../pages/404";

const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!) {
    properties(where:{id: $id}) {
      id
      search_type
      status
      publish
      department
      display_address
      price
      price_qualifier
      building
      bedroom
      bathroom
      reception
      floorplan
      images
      imagetransforms
      description
      extra
      epc
      selling_info
      letting_info
      address
      latitude
      longitude
      brochure
      postcode
      slug
      virtual_tour
      crm_area_id
    }
  }
`;

const PropertyDetailsTemplate = (props) => {

    // 
    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
    // 

    //
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    let myid_filt = pageurl.split('?');
    let mypageurl = myid_filt[0];
    let property_slug = mypageurl.split('-');
    let id = property_slug[property_slug.length - 1];
    id = id.replace("/", "");

    const { loading, error, data } = useQuery(PROPERTY_DETAILS, {
        variables: { id: id }
    });

    // Loading logo
    if (loading) return (
        <section className={"loader-wrapper"}>
            <div id="loader-wrapper">
                <div id="loader" className="new-loader">
                    <img className="logo-white loader-logo" src={BrandLogo} alt="logo"/>
                </div>
            </div>
        </section>
    );
    // Loading logo
    //

  // Set Popular Search 
  var popularsearch = "Sales_Links";
  if (typeof window !== 'undefined' && window.location.href.indexOf("to-rent") != -1) {
    popularsearch = "Rent_Links";
  }
  // 

  // 404 page
  const property = data?.properties?.[0];
  const status = property?.status;

  if (status && !property.publish && ["letByOtherAgent", "withdrawn", "For Sale"].includes(status)) {
    return <NotFoundPage />;
  }
  // 404 page

  return (
    <>
      <Header/>
      {
        data && data.properties && data.properties.length > 0 ?
        (
          data.properties.map((data, i) => {
            const building = data?.building?.length > 0 && data.building[0]
            var searchaction = data.search_type === "sales"?"for sale":"to let"            
            var metatile = ""+capitalize(building ? building?.replace(/-/g, ' ') : 'property')+" "+searchaction+" with "+data.bedroom+" bedrooms in "+data.display_address+" at £"+data.price.toLocaleString();
            var metadesc = "Know the details of "+capitalize(building?.replace(/-/g, ' '))+" "+searchaction+" with "+data.bedroom+" bedrooms in "+data.display_address+" at £"+data.price.toLocaleString()+". Book a viewing to get assistance in finding the right "+capitalize(building?.replace(/-/g, ' '))+" for you.";
            //console.log("buildingbuilding", data.building[0])

            return (
              <React.Fragment key={i}>
                <SEO image={data.images && data.images[0].url} title={metatile} description={metadesc} />
                <PropertyBreadcrumb propertyData={data} />
                <PropertyDetailsSlider propertyData={data} />
                <PropertyDetailsDescription propertyData={data} />
                {data?.latitude && data?.longitude && (
                  <PropertyDetailsMap
                    lat={data?.latitude}
                    lng={data?.longitude}
                  />
                )}
                <PropertyAreaGuide crm_area_id={data.crm_area_id}/>
                <SimilarProperties propertyData={data} />
                <FullWidthBlueBanner type="letus" />
              </React.Fragment>
            )
          })
        )
        :
        ''
      }
      <Footer popularsearch={popularsearch}/>
    </>
  )
}

const PropertyDetails = (props) => {
  return (
    <UserObjectStoreProvider>
      <PropertyDetailsTemplate {...props} />
    </UserObjectStoreProvider>
  )
}

export default PropertyDetails