import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import parse from "html-react-parser"
import StampDutyCalc from "../calculators/stampdutycalc/property-stampduty"
import './assets/styles/_index.scss';

const PropertyDetailsDescription = (props) => {

    const Deposit = ({ letting_info }) => {
        const {deposit: { type: depositType, amount: depositAmount } = {}, rent} = letting_info || {};
        const deposit = depositType === "fixed" ? depositAmount : depositType === "weeks" ? depositAmount * rent : '';
        return deposit ? deposit.toLocaleString() : null;
    };

    return (
        <section className="property-description-wrapper">
            <Container>
                <Row>
                    <Col lg={7}>
                        <h6>Property <span>Description</span></h6>
                        {
                            props?.propertyData?.description &&
                            <p className="property-desc">{parse(props?.propertyData?.description)}</p>
                        }                        
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={4}>
                        <h6 className="key-details-heading">Key <span>Details</span></h6>
                        <ul className="property-key-details">
                            {
                                props?.propertyData?.search_type === "sales" ?
                                <>
                                    {
                                        props?.propertyData?.selling_info?.tenure?.type === "freehold" ?
                                        <li>
                                            <div className="key-details-title">Tenure</div>
                                            <div className="key-details">{props?.propertyData?.selling_info?.tenure?.type}</div>
                                        </li>
                                        :
                                        props?.propertyData?.selling_info?.tenure?.type === "leasehold" ? 
                                        <>
                                            <li>
                                                <div className="key-details-title">Tenure</div>
                                                <div className="key-details">{props?.propertyData?.selling_info?.tenure?.type}</div>
                                            </li>
                                            {
                                                props?.propertyData?.extra?.groundRent > 0 &&
                                                <li>
                                                    <div className="key-details-title">Current Ground Rent</div>
                                                    <div className="key-details">£{props?.propertyData?.extra?.groundRent.toLocaleString()}</div>
                                                </li>
                                            }
                                            {
                                                props?.propertyData?.extra?.serviceCharge > 0 &&
                                                <li>
                                                    <div className="key-details-title">Current Service Charge</div>
                                                    <div className="key-details">£{props?.propertyData?.extra?.serviceCharge.toLocaleString()}</div>
                                                </li>
                                            }
                                        </>
                                        :
                                        <li>
                                            <div className="key-details-title">Tenure</div>
                                            <div className="key-details">{props?.propertyData?.selling_info?.tenure?.type}</div>
                                        </li>
                                    }
                                </>
                                :
                                <>
                                    {/* <li>
                                        <div className="key-details-title">Move in date</div>
                                        <div className="key-details">24th July, 2022</div>
                                    </li> */}
                                    {
                                        props?.propertyData?.letting_info?.deposit?.amount > 0 && props?.propertyData?.letting_info?.rent &&
                                        <li>
                                            <div className="key-details-title">Deposit</div>
                                            <div className="key-details">£{<Deposit letting_info={props?.propertyData?.letting_info} />}</div>
                                        </li>
                                    }
                                    {
                                        props?.propertyData?.letting_info?.term &&
                                        <li>
                                            <div className="key-details-title">Let type</div>
                                            <div className="key-details text-capitalize">{props?.propertyData?.letting_info?.term} Term</div>
                                        </li>
                                    }
                                </>
                            }
                            {
                                props?.propertyData?.epc?.order &&
                                <li>
                                    <div className="key-details-title">EPC Rating</div>
                                    <div className="key-details">{props?.propertyData?.epc?.order}</div>
                                </li>
                            }
                            {
                                props?.propertyData?.extra?.councilTax &&
                                <li>
                                    <div className="key-details-title">Council Tax Band</div>
                                    <div className="key-details">{props?.propertyData?.extra?.councilTax}</div>
                                </li>
                            }
                        </ul>
                    </Col>
                </Row>

                <Row>
                    <Col lg={12}>
                        {
                            props?.propertyData?.search_type === "sales" ?
                            <div className="property-stamp-duty-calc">
                                <div className="calc-border-line"></div>
                                <div className="calc-title">Stamp Duty Calculator</div>
                                <StampDutyCalc propertyValue={props?.propertyData?.price} />
                            </div>
                            : ""
                        }
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PropertyDetailsDescription