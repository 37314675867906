import React from 'react';
import { Container, Col, Row, Button } from "react-bootstrap";
import { Link } from 'gatsby';
import './assets/styles/_index.scss';

const PropertyBreadcrumb = (props) => {

    const handleBack = () => typeof window !== "undefined" && window.history.back();

    return (
        <section className="breadcrumb-wrapper d-flex align-items-center">
            <Container>
                <Row>
                    <Col>
                        <div className="d-flex">
                            <Link className="breadcrumb-back d-md-flex align-items-center" to={"/property/"+(props?.propertyData?.search_type === "sales" ? "for-sale" : "to-rent")+"/in-london/"}><i className="icon icon-back-arrow"></i> Back to Listings</Link>
                            {/* <a href="javascript:void(0)" onClick={handleBack} className="breadcrumb-back d-md-flex align-items-center"><i className="icon icon-back-arrow"></i> Back to Listings</a> */}
                            <Link to="/" className="d-lg-block d-none">Home</Link>
                            <div className="vertical-divider d-lg-block d-none"></div>
                            <Link className="d-lg-block d-none" to={"/property/"+(props?.propertyData?.search_type === "sales" ? "for-sale" : "to-rent")+"/in-london/"}>Properties</Link>
                            <div className="vertical-divider d-lg-block d-none"></div>
                            <Link className="d-lg-block d-none" to={"/property/"+(props?.propertyData?.search_type === "sales" ? "for-sale" : "to-rent")+"/in-london/"}>Property {props?.propertyData?.search_type === "sales" ? "for sale" : "to rent"} in London</Link>
                            <div className="vertical-divider d-lg-block d-none"></div>
                            <div className="current-page d-lg-block d-none">{props?.propertyData?.display_address}</div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PropertyBreadcrumb