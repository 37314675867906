import React, { useState, useEffect } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from 'gatsby';
import axios from 'axios';
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import GetGGFXImage from "../common/site/get-ggfx-image"
import './assets/styles/_index.scss';

const SimilarProperties = (props) => {

    const [propItems, setPropItems] = useState(false)
    
    const getitems = async url => {
        try {
          const { data } = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
            }
          })
          setPropItems(data.filter(x => x.id !== props.propertyData.id))
        } catch (error) {
          console.error(error)
        }
    }

    useEffect(() => {
        let postCode = props?.propertyData?.postcode;
        let postCodearr = postCode.split(" ")[0];

        let url = process.env.GATSBY_STRAPI_SRC + `/properties?search_type=${props?.propertyData?.search_type}&_status=${props?.propertyData?.search_type === "sales" ? "Sold" : "Let"}&_limit=3&_sort=price:DESC&publish=true`; // TODO: base URL supposed to be from .env
        
        if (postCodearr) {
            url = url + "&postcode_contains=" + postCodearr;
        }
    
        getitems(url)
    },[props?.propertyData])
    

    // Slider settings
    let settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
        {
            breakpoint: 1200,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            arrows: false,
            dots: false,
            },
        },
        {
            breakpoint: 992,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            arrows: false,
            dots: true,
            },
        },
        {
            breakpoint: 767,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            arrows: false,
            dots: true,
            },
        },
        ],
    }
    // Slider settings

    return (
        <>
            {
                propItems && propItems.length > 0 &&
                <section className="similar-properties-wrapper our-people-slider-wrapper">
                    <Container>
                        <Row>
                            <Col>
                                <div className="our-people-sm-text text-uppercase">Track Record</div>
                                <h2 className="our-people-heading">Properties we’ve recently {props?.propertyData?.search_type === "sales" ? "Sold" : "Let"}</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Slider className="our-people-slider" {...settings}>
                                    {
                                        propItems && propItems.map ((item, key) => {
                                            
                                            let processedImages = JSON.stringify({});
                                            if (item?.imagetransforms?.images_Transforms) {
                                                processedImages = item.imagetransforms.images_Transforms;
                                            }

                                            let propertyUrl = (item.search_type === "sales" ? "/property-for-sale/" : "/property-to-rent/")+item.slug+"-"+item.id+"/";

                                            return (
                                                <div className="our-people-slide" key={key}>
                                                    <div className="our-people-img-zoom">
                                                        <div className="track-slide-label d-flex align-items-center justify-content-center">{item?.status}</div>
                                                        <Link to={propertyUrl}>
                                                            <GetGGFXImage 
                                                                imagename={"property.images.similardetail"} 
                                                                imagesource={item?.images[0]}
                                                                fallbackalt={item?.display_address} 
                                                                imagetransformresult={processedImages} 
                                                                id={item?.id}
                                                            />
                                                        </Link>
                                                        <div className="our-people-slide-display-adderss-wrapper d-flex justify-content-md-between justify-content-end">
                                                            <div className="our-people-slide-display-address d-md-flex align-items-center justify-content-center d-none">
                                                                <Link to={propertyUrl}>
                                                                    <span>{item?.display_address}</span>
                                                                </Link>
                                                            </div>
                                                            <Link to={propertyUrl} className="btn-link">
                                                                <i className="icon icon-track-slider-link-arrow"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="d-md-none d-block our-people-slide-display-address-sm">
                                                        <Link to={propertyUrl}>
                                                            <span>{item?.display_address}</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </section>
            }
        </>
    )
}

export default SimilarProperties