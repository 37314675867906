import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from 'gatsby';
import parse from "html-react-parser"
import { AreaGuideDetail } from '../../queries/common_use_query'
import GetGGFXImage from "../common/site/get-ggfx-image"
import './assets/styles/_index.scss';

const PropertyAreaGuide = (props) => {
    const {loading:loading, error:error, data:data} = AreaGuideDetail(props.crm_area_id);

    return (
        <>
            {
                data && data?.areaguides.map((item ,i) => {
                    let processedImages = JSON.stringify({});
                    if (item?.imagetransforms?.Banner_Image_Transforms) {
                        processedImages = item.imagetransforms.Banner_Image_Transforms;
                    }



                        return (
                            <section className="property-areaguide-wrapper">
                                <Container>
                                    <Row>
                                        <Col xl={7}>
                                            <picture>
                                                <GetGGFXImage 
                                                    imagename={"areaguides.Banner_Image.propertyDetailImg"}
                                                    imagesource={item.Banner_Image}
                                                    fallbackalt={item.Areaguide_Title}
                                                    imagetransformresult={processedImages}
                                                    id={item?.id}
                                                />
                                            </picture>
                                        </Col>
                                        <Col xl={1}></Col>
                                        <Col xl={4}>
                                            <div className="area-guide-right-block d-flex align-items-center">
                                                <div>
                                                    <div className="areaguide-title-sm">Local Area Guide</div>
                                                    <h5 className="areaguide-title">{item.Areaguide_Title} offers a <span>unique village</span> atmosphere.</h5>
                                                    <p className="p-0">{parse(item.Areaguide_Intro)}</p>
                                                    <Link to={"/about/area-guides/"+item.URL+"/"} className="btn btn-white-outline">View Area Guide</Link>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        )
                })
            }
        </>
    )
}

export default PropertyAreaGuide